.authenticaResult {
  padding-top: 20vh;
  width: 100%;
  height: 100vh;
}

.authenticaResult .result-img {
  width: 20vw;
  margin: 0 auto;
}

.authenticaResult .result-img img {
  width: 100%;
}

.authenticaResult .result-text {
  text-align: center;
  margin-top: 0.2rem;
  font-size: 0.35rem;
  color: #1890ff;
}

.authenticaResult .fail {
  color: #f07070;
}

.authenticaResult .time-text {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.35rem;
  color: #cccccc;
}

.authenticaResult .return-btn {
  text-align: center;
  font-size: 0.37rem;
  background-color: #1891ff;
  color: #ffffff;
  width: 50%;
  padding: 0.2rem 0;
  margin: 2rem auto 0 auto;
}